body,
html {
    color: #EAEAEA;
    font-family: "Times New Roman", Times, serif;
    margin: 0;
    padding: 0;
}

.button1 {
    border-radius: 25px;
    border: 2px solid #EAEAEA;
    margin: 10px;
    padding: 4px;
    width: 200px;
}

.button2 {
    border-radius: 25px;
    border: 2px solid #EAEAEA;
    margin: 10px;
    padding: 4px;
    width: 100px;
    background-color: #5E3DC3;
}

::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #EAEAEA;
    opacity: 1;
    /* Firefox */
}

.icon_40x40 {
    width: 40px;
    height: 40px;
    background-repeat: no-repeat;
    background-size: contain;
}

.usernameDisplay {
    position: fixed;
    top: 10;
    right: 10;
}

.reactionIcon {
    position: fixed;
    background-color: rgba(51, 51, 51, 0.6);
    width: 46px;
    height: 46px;
    border-radius: 10px;
}

.reactionIcon div {
    background-repeat: no-repeat;
    background-size: contain;
    width: 34px;
    height: 34px;
    margin-top: 4px;
}

.reactionIcon:hover {
    /* Start the shake animation and make the animation last for 0.5 seconds */
    animation: shake 0.5s;
    /* When the animation is finished, start again */
    animation-iteration-count: infinite;
}

.shake {
    /* Start the shake animation and make the animation last for 0.5 seconds */
    animation: shake 0.3s;
    /* When the animation is finished, start again */
    animation-iteration-count: 1;
}

@keyframes shake {
    0% {
        transform: translate(1px, 1px) rotate(0deg);
    }
    10% {
        transform: translate(-1px, -2px) rotate(-1deg);
    }
    20% {
        transform: translate(-3px, 0px) rotate(1deg);
    }
    30% {
        transform: translate(3px, 2px) rotate(0deg);
    }
    40% {
        transform: translate(1px, -1px) rotate(1deg);
    }
    50% {
        transform: translate(-1px, 2px) rotate(-1deg);
    }
    60% {
        transform: translate(-3px, 1px) rotate(0deg);
    }
    70% {
        transform: translate(3px, 1px) rotate(-1deg);
    }
    80% {
        transform: translate(-1px, -1px) rotate(1deg);
    }
    90% {
        transform: translate(1px, 2px) rotate(0deg);
    }
    100% {
        transform: translate(1px, -2px) rotate(-1deg);
    }
}